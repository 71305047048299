import React, { useEffect, useState } from 'react'
import { IoClose } from "react-icons/io5";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import uploadFile from '../helpers/uploadFile';
import axios from 'axios'
import toast from 'react-hot-toast';
import { PiUserCircle } from "react-icons/pi";
import Avatar from '../components/Avatar';
import { useDispatch } from 'react-redux';
import { setToken, setUser } from '../redux/userSlice';
import { FaDatabase } from 'react-icons/fa';

const CheckPasswordPage = () => {
  const [data, setData] = useState({
    password: "",
    userId: ""
  })
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  console.log('location ', location?.state?.rows[0].name);
  useEffect(() => {
    if (!location?.state?.rows[0].name) {
      navigate('/email')
    }
  }, [])

  const handleOnChange = (e) => {
    const { name, value } = e.target

    setData((preve) => {
      return {
        ...preve,
        [name]: value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()


    let URL = `${process.env.REACT_APP_BACKEND_URL}/intercom/password`; 
    if (window.location.href.includes('localhost')) {
    URL =  `${process.env.REACT_APP_BACKEND_LOCAL_URL}${process.env.REACT_APP_PORT_API_LOCAL}/intercom/password`;
    }
    
    console.log(location?.state, data);
    try {
/*       const response = await axios.post(URL, {
        userId: location?.state?.rows[0].id,
        password: data.password
      }) */
        const response = await axios.post(
     
          URL,
        {  userId: location?.state?.rows[0].id,
            password: data.password
          },
          {withCredentials: true}
          
        ) 
   /*     const response = await axios({
        method: 'post',
        url: URL,
        data: {
          userId: location?.state?.rows[0].id,
          password: data.password
        },
        withCredentials: true

      })  */

      toast.success(response.data.message)
      console.log('setting token',response.data.success);
      if (response.data.success) {
        dispatch(setToken(response?.data?.token))
        localStorage.setItem('token', response?.data?.token)

        setData({
          password: "",
        })
        navigate('/')
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }


  return (
    <div className='mt-5'>
      <div className='bg-white w-full max-w-md  rounded overflow-hidden p-4 mx-auto'>

        <div className='w-fit mx-auto mb-2 flex justify-center items-center flex-col'>
          {/* <PiUserCircle
                  size={80}
                /> */}
          <Avatar
            width={70}
            height={70}
            name={location?.state?.rows[0].name}
            imageUrl={location?.state?.rows[0].profile_pic}
            userId={location?.state?.rows[0].id}
          />
          <h2 className='font-semibold text-lg mt-1'>{location?.state?.rows[0].name}</h2>
        </div>

        <form className='grid gap-4 mt-3' onSubmit={handleSubmit}>


          <div className='flex flex-col gap-1'>
            <label htmlFor='password'>Пароль :</label>
            <input
              type='password'
              id='password'
              name='password'
              placeholder='Введите пароль'
              className='bg-slate-100 px-2 py-1 focus:outline-primary'
              value={data.password}
              onChange={handleOnChange}
              required
            />
          </div>

          <button
            className='bg-primary text-lg  px-4 py-1 hover:bg-secondary rounded mt-2 font-bold text-white leading-relaxed tracking-wide'
          >
            Войти
          </button>

        </form>

        <p className='my-3 text-center'><Link to={"/forgot-password"} className='hover:text-primary font-semibold'>Забыли пароль?</Link></p>
      </div>
    </div>
  )
}

export default CheckPasswordPage


import React from 'react'
import Avatar from './Avatar'
import { Link } from 'react-router-dom'

const ItemSearchCard = ({item, onClose}) => {
    
    let URL = `${process.env.REACT_APP_BACKEND_URL}`

    if (window.location.href.includes('localhost')) {
        URL = `${process.env.REACT_APP_BACKEND_LOCAL_URL}:4000`;
    }

  return (
    <Link /* onClick={onClose}  */className='flex items-center gap-3 p-2 lg:p-4 border border-transparent border-b-slate-200 hover:border hover:border-primary rounded cursor-pointer'>
        <div>
            <Avatar
                width={50}
                height={50}
                name={item?.name}
                userId={item?.id}
                imageUrl={`${URL}/images/${item?.image}${item?.image.includes(".png") ? '' : ".png"}`}
            />
        </div>
        <div>
            <div className='font-semibold text-ellipsis line-clamp-1'>
                {item?.name}
            </div>
            <div className='font-semibold text-ellipsis line-clamp-1'>
                {item?.composition}
            </div>
            <p className='text-sm text-ellipsis line-clamp-1'>Артикул: {item?.id}</p>
        </div>
    </Link>
  )
}

export default ItemSearchCard

import React, { useEffect, useRef, useState } from 'react'
import { IoSearchOutline } from "react-icons/io5";
import Divider from './Divider'
import axios from 'axios'
import taost from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import './OrderPage.css'
import Loading from './Loading';
import ItemSearchCard from './ItemSearchCard';
let styleInline = '';



const EditOrderDetails = ({ onClose, user, order }) => {

    const [disabled, setDisabled] = useState(order?.correctedAmount)
    const [comment, setComment] = useState(order?.comment)
    const [payment, setPayment] = useState(false)
    const [renderItems, setRenderItems] = useState([])
    const [total, setTotal] = useState(order?.amount)
    const [data, setData] = useState([])
    const [searchButtonText, setSearchButtonText] = useState('Показать поиск')
    let searchindex = 99
    const [numbers, setNumbers] = useState(0)
    const spacer = process.env.REACT_APP_PORT_SPACER
    let upItems = []

    useEffect(() => {
        const spacer = process.env.REACT_APP_PORT_SPACER
        upItems = order.items.map((item, index) => {
            return `${item.split(spacer)[0]}${spacer}${item.split(spacer)[1]}${spacer}${item.split(spacer)[2]}${spacer}${parseInt(data[index])}${spacer}${item.split(spacer)[4]}`
        })
        setRenderItems(upItems)
     console.log(`---->upItems ${renderItems}`, upItems)
     upOrder.comment = comment
     upOrder.items = renderItems
    }, [data,comment])


    useEffect(() => {

        console.log('order.items', order.items);
        upItems = order.items.map((item, index) => {
            return `${item.split(process.env.REACT_APP_PORT_SPACER)[0]}${process.env.REACT_APP_PORT_SPACER}${item.split(process.env.REACT_APP_PORT_SPACER)[1]}${process.env.REACT_APP_PORT_SPACER}${item.split(process.env.REACT_APP_PORT_SPACER)[2]}${process.env.REACT_APP_PORT_SPACER}${item.split(process.env.REACT_APP_PORT_SPACER)[3]}${process.env.REACT_APP_PORT_SPACER}${item.split(process.env.REACT_APP_PORT_SPACER)[4]}`
        })
        setRenderItems(upItems)
        console.log('upItemsbefore', upItems);
        let arr = order.items.map((item, index) => {
            return item.split(process.env.REACT_APP_PORT_SPACER)[3]
        })
        console.log('arr', arr);
        setData(arr)
        let numbers = order.items.map((item, index) => {
            return parseInt(item.split(process.env.REACT_APP_PORT_SPACER)[3]) * parseInt(item.split(process.env.REACT_APP_PORT_SPACER)[2]);
        }).reduce((acc, number) => acc + number, 0)
        console.log('start numbers', numbers);

        setNumbers(numbers)
    }, [])

    // console.log(upItems);

    let upOrder = {
        items: renderItems,
        amount: total,
        orderId: order?.id,
        orderUserId: order?.userId,
        userId: user?.id,
        statusOrder: '',
        statusUser: user?.status,
        assemblerName: order?.name,
        correctedAmount: disabled,
        comment: comment,
        payment: payment
    }
    const dispatch = useDispatch()

 


    //const getNumbers = (renderItems, data) => {
    let num = renderItems?.map((item, index) => {
        return parseInt(data[index]) * parseInt(item?.split(process.env.REACT_APP_PORT_SPACER)[2]);
    }).reduce((acc, num) => acc + num, 0)

  //  
   let block = document.getElementById("checkbox")
    console.log(block,);
    
    if ((block !== null)&&(block?.checked)) { upOrder.amount =  total}
     else { upOrder.amount = num}

    console.log(`current numbers${block?.checked}`, upOrder.amount);

    // numbers = num
    //  setNumbers(num)
    /* }
    getNumbers(renderItems,data) */

    const handleOnChange = (index, event) => {
        setData([
            ...data.slice(0, index),
            event.target.value,
            ...data.slice(index + 1)
        ]);

        console.log('setting data', ...data);
    }
    const handleAssemble = async (e) => {
        console.log(user);
        try {
            upOrder.statusOrder = 'ДАЛЕЕ'
            upOrder.statusUser = user?.status
           /*  changedItems(order,renderItems, setRenderItems,data, upOrder) */
            upOrder.items = renderItems

            let URL = `${process.env.REACT_APP_BACKEND_URL}/api/order/update`
            if (window.location.href.includes('localhost')) {
                URL = `${process.env.REACT_APP_BACKEND_LOCAL_URL}${process.env.REACT_APP_PORT_API_APP_LOCAL}/api/order/update`;
            }
            console.log(upOrder);
            const response = await axios.post(URL, upOrder)

            taost.success(response?.data?.message)

            if (response.data.success) {
                // dispatch(setUser(response.data.data))
                onClose()
            }

        } catch (error) {
            console.log(error)
            taost.error()
        }

    }
    const handleAccept = async (e) => {
        try {
            //upOrder.statusOrder = ''
            upOrder.statusUser = user?.status
          /*   changedItems(order,renderItems, setRenderItems,data, upOrder) */
            upOrder.items = renderItems
            let URL = `${process.env.REACT_APP_BACKEND_URL}/api/order/update`

            if (window.location.href.includes('localhost')) {
                URL = `${process.env.REACT_APP_BACKEND_LOCAL_URL}${process.env.REACT_APP_PORT_API_APP_LOCAL}/api/order/update`;
            }
            console.log(upOrder);
            const response = await axios.post(URL, upOrder)

            taost.success(response?.data?.message)

            if (response.data.success) {
                // dispatch(setUser(response.data.data))
                onClose()
            }

        } catch (error) {
            console.log(error)
            taost.error()
        }

    }
    const handleReturn = async (e) => {
        try {
            upOrder.statusOrder = 'НАЗАД'
            upOrder.statusUser = user?.status
         /*    changedItems(order,renderItems, setRenderItems,data, upOrder) */
            upOrder.items = renderItems

            let URL = `${process.env.REACT_APP_BACKEND_URL}/api/order/update`

            if (window.location.href.includes('localhost')) {
                URL = `${process.env.REACT_APP_BACKEND_LOCAL_URL}${process.env.REACT_APP_PORT_API_APP_LOCAL}/api/order/update`;
            }
            console.log(upOrder);
            const response = await axios.post(URL, upOrder)

            taost.success(response?.data?.message)

            if (response.data.success) {
                // dispatch(setUser(response.data.data))
                onClose()
            }

        } catch (error) {
            console.log(error)
            taost.error()
        }

    }
    const handleSubmit = async (e) => {

        /*         console.log(numbers)*/
        console.log('upOrder', upOrder)

        e.preventDefault()
        e.stopPropagation()

        try {
         /*    changedItems(order,renderItems, setRenderItems,data, upOrder) */
            upOrder.items = renderItems

            let URL = `${process.env.REACT_APP_BACKEND_URL}/api/order/update`

            if (window.location.href.includes('localhost')) {
                URL = `${process.env.REACT_APP_BACKEND_LOCAL_URL}${process.env.REACT_APP_PORT_API_APP_LOCAL}/api/order/update`;
            }

            const response = await axios.post(URL, upOrder)
            console.log('yes------>', upOrder)
            console.log('response', response)
            //  console.log(socketConnection);

            //  socketConnection.emit('sidebar', user.id)

            taost.success(response?.data?.message)

            if (response.data.success) {
                // dispatch(setUser(response.data.data))
                onClose()
            }

        } catch (error) {
            console.log(error)
            taost.error()
        }
    }

    const handlePayment = async (e) => {
        try {
            //  setPayment(true)
            upOrder.payment = true
       /*      changedItems(order,renderItems, setRenderItems,data, upOrder) */
            upOrder.items = renderItems
            let URL = `${process.env.REACT_APP_BACKEND_URL}/api/order/update`
            if (window.location.href.includes('localhost')) {
                URL = `${process.env.REACT_APP_BACKEND_LOCAL_URL}${process.env.REACT_APP_PORT_API_APP_LOCAL}/api/order/update`;
            }
            console.log(renderItems, payment);
            const response = await axios.post(URL, upOrder)
            taost.success(response?.data?.message)
            if (response.data.success) {
                //  onClose()
            }

        } catch (error) {
            console.log(error)
            taost.error()
        }

    }
    const clickHandler = async (e) => {

        if (disabled) {
            setDisabled(false)
            setTotal(num)
        }
        else {
            setDisabled(true)

        }

        //  upOrder?.correctedAmount = disabled 
    }

    const onChangeComment = async (e) => {
        setComment(e.target.value)
    }

    const handleTotalOnChange = async (e) => {
        setTotal(e.target.value)
    }
    const handlerAdd = () => {
        let block = document.getElementById("addForm")
        if (block.style.display == 'block') { block.style.display = 'none'; setSearchButtonText('Показать поиск') }
         else { block.style.display = 'block';  setSearchButtonText('Скрыть поиск')}

        console.log(block);
        
    }
    const handleSubmitAdd = async (e) => {

    }
    const SearchOnChange = (item) => {

        const upItems = [...renderItems]
        console.log(upItems)
        upItems.push(`${item.id}${process.env.REACT_APP_PORT_SPACER}${item.name}${process.env.REACT_APP_PORT_SPACER}${item.price}${process.env.REACT_APP_PORT_SPACER}1${process.env.REACT_APP_PORT_SPACER}${item.composition?.replaceAll(",",'&#44;')}`);
        console.log(upItems)
        setRenderItems(upItems)
        console.log('upItems', renderItems)

        let arr = upItems.map((item, index) => {
            console.log(item);

            return item.split(process.env.REACT_APP_PORT_SPACER)[3]
        })
        console.log('arr', arr);

        setData(arr)

        let numbers = renderItems.map((item, index) => {
            return parseInt(data[index]) * parseInt(item.split(process.env.REACT_APP_PORT_SPACER)[2]);
        }).reduce((acc, number) => acc + number, 0)
        console.log('numbers', numbers);

        setNumbers(numbers)
    }


    const [searchItem, setSearchItem] = useState([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSearchItem = async () => {

        let URL = `${process.env.REACT_APP_BACKEND_URL}/intercom/search-item`

        if (window.location.href.includes('localhost')) {
            URL = `${process.env.REACT_APP_BACKEND_LOCAL_URL}${process.env.REACT_APP_PORT_API_LOCAL}/intercom/search-item`;
        }
        console.log('search', search);

        try {
            setLoading(true)
            const response = await axios.post(URL, {
                search: search
            })
            setLoading(false)
            setSearchItem(response.data.data)
            console.log(searchItem);

        } catch (error) {
            console.log(error);

        }
    }
    /*    useEffect(() => {
       },[]) */

    useEffect(() => {
        handleSearchItem()
        console.log(search.length, search);
        let block = document.getElementById("src");
        if ((block) && (search.length == 0)) { block.style.display = 'none' } 
        else { block.style.display = 'block'  }
    }, [search])

    return (

        <div class="z-40 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
            id="exampleModalScrollable" tabindex="-1" aria-labelledby="exampleModalScrollableLabel" aria-hidden="true">
            <div class="sm:h-[calc(100%-3rem)] max-w-lg my-6 mx-auto relative w-auto pointer-events-none">
                <div
                    class="max-h-full overflow-hidden border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div
                        class="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">

                        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                            Заказ № {order?.id}
                        </h5>

                        {(order?.name?.length > 0) && (<> работает: {order?.name}</>)}
                    </div>
                    <div className="flex-auto overflow-y-auto relative p-4">

                        <div className='bg-white p-2 py-1 m-1 rounded w-full max-w-md'>
                            <p className='text-sm '>Внимание! внесение изменений в заказ повлечет за собой корректировку стоимости заказа </p>
                            <br />
                            <button type="button" onClick={handlerAdd}
                                class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                                {searchButtonText}
                            </button>
                            <form className='grid gap-3 mt-3' onSubmit={handleSubmitAdd} style={{ display: 'none' }} id='addForm'>

                                <div className=' rounded  flex  '>
                                    <input
                                        type='text'
                                           className='w-full py-1 px-2 focus:outline-primary border-0.5 ring-1'
                                        name={searchindex}
                                        id={searchindex}
                                        value={search}
                                        onChange={(e) => {
                                            setSearch(e.target.value)

                                        }}
                               
                                    />
                                    <div className='h-14 w-14 flex justify-center items-center'>
                                        <IoSearchOutline size={25} />
                                    </div>

                                    <div id='src' className='z-40 fixed top-72 bg-black text-white bg-opacity-75 inline-block w-[450px] outline-none overflow-x-hidden overflow-y-auto'>
                                        {/**no user found */}
                                        {
                                            searchItem.rowCount == 0 && !loading && (
                                                <p className='text-center text-slate-500 text-white'>Товар не найден!</p>
                                            )
                                        }

                                        {
                                            loading && (
                                                <p className='w-50'><Loading /></p>
                                            )
                                        }

                                        {
                                            searchItem.rowCount !== 0 && !loading && (
                                                searchItem?.rows?.map((item, index) => {
                                                    //console.log(`${searchItem.rows[index].id} ->`, item)
                                                    return (
                                                        <a onClick={() => { SearchOnChange(item) }}>
                                                            <ItemSearchCard key={searchItem.rows[index].id} item={item} onClose={(onClose)} />
                                                        </a>
                                                    )
                                                })
                                            )
                                        }


                                    </div>
                                </div>

                            </form>

                            <form id='items' className='grid gap-3 mt-3' onSubmit={handleSubmit}>
                                {/*   {console.log(renderItems)} */}
                                {
                                    renderItems.map((conv, index) => {

                                        console.log('rerending')

                                        return (

                                            <div className='flex flex-col gap-1'>
                                                <Divider />
                                                <label htmlFor='art'>Артикул: {conv.split(process.env.REACT_APP_PORT_SPACER)[0]}</label>
                                                <label htmlFor='name'>Наименование: {conv.split(process.env.REACT_APP_PORT_SPACER)[1]}</label>
                                                <label htmlFor='count'>кол-во:
                                                </label>

                                                <input
                                                    type='text'
                                                    name={index}
                                                    id={index}
                                                    value={data[index]}
                                                    onChange={event => { handleOnChange(index, event) }}
                                                    className='w-1/2 py-1 px-2 focus:outline-primary border-0.5 ring-1'
                                                />
                                                <label htmlFor='name'>Цена: {conv.split(process.env.REACT_APP_PORT_SPACER)[2]}</label>

                                                <div class="css-modal-details">
                                                     <button type="button" onClick={handleOnChange}
                                class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                               Фото
                            </button> 
                                                    <details>
                                                        <summary>
                                                            Состав</summary>
                                                        <div class="cmc">
                                                            <div class="cmt">
                                                                <p><>{conv.split(process.env.REACT_APP_PORT_SPACER)[4].replaceAll('2&#44;', ",")}</></p>
                                                            </div>
                                                        </div>
                                                    </details>
                                                    <button type="button" onClick={handleOnChange}
                                class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                               удалить
                            </button> 
                                                </div>


                                            </div>
                                        )
                                    })
                                }
                            </form>
                            <Divider />

                            <div className='flex flex-col gap-1'>
                                <label htmlFor='comment'>Комментарий: </label>
                                <textarea
                                    className='w-full py-1 px-2 focus:outline-primary border-0.5 ring-1'
                                    rows={4}
                                    onChange={event => onChangeComment(event)}
                                    value={comment}>
                                </textarea>
                            </div>
                        </div>
                    </div>


                    <div class="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">

                        <Divider />
                        <div className='flex gap-2  '>
                            <input
                            id="checkbox"
                                type="checkbox"
                                name="html5"
                                class="inline-block px-6 py-2.5 bg-blue-600 text-white "
                                checked={disabled}
                                onClick={clickHandler} />
                            Ручная кор. итог

                            <input
                                type='text'
                                name='total'
                                id='total'
                                value={total}
                                onChange={event => handleTotalOnChange(event)}
                                className='w-1/2 py-1 px-2 focus:outline-primary border-0.5 ring-1'
                                disabled={!disabled}
                            />
                        </div>
                        <div className='flex gap-2 w-fit ml-auto '>
                            <label htmlFor='name'>ИТОГО: {num}
                            </label>
                        </div>


                    </div>
                    <div
                        class="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                        {((order.order_operator_id == null && user?.status == 2) || (order.order_assembler_id == null && user?.status == 1)) && (<button type="button" onClick={handleAccept}
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            data-bs-dismiss="modal">
                            принять
                        </button>)}

                        {(user?.id == order.order_operator_id
                            && order.order_assembler_id == null
                            && order.status !== 'СДАН СБОРЩИКАМ'
                            && order.status !== 'ПИНЯТ В СБОРКУ'
                        )
                            && (<button type="button" onClick={handleAssemble}
                                class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                data-bs-dismiss="modal">
                                в сборку
                            </button>)}
                        {((user?.id == order.order_operator_id || user?.id == order.order_assembler_id)
                            && order.status !== 'В ОБРАБОТКЕ'
                            && order.status !== 'У ОПЕРАТОРА'
                        ) && (
                                <button type="button" onClick={handleReturn}
                                    class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                                    отозвать
                                </button>)}
                        {(user?.id == order.order_operator_id || user?.id == order.order_assembler_id) && (
                            <button type="button" onClick={handlePayment}
                                class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                                на оплату
                            </button>)}

                        <div className='flex gap-2 w-fit ml-auto '>
                            <button onClick={onClose} className='inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1'>X</button>
                            {(user?.id == order.order_operator_id || user?.id == order.order_assembler_id) && (<button onClick={handleSubmit} className='inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1'>&#128190;</button>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default React.memo(EditOrderDetails)


import React, { useEffect, useState } from 'react'
import { IoChatbubbleEllipses } from "react-icons/io5";
import { FaUserPlus } from "react-icons/fa";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { BiLogOut } from "react-icons/bi";
import Avatar from './Avatar'
import { useDispatch, useSelector } from 'react-redux';
import EditUserDetails from './EditUserDetails';
import Divider from './Divider';
import { FiArrowUpLeft } from "react-icons/fi";
import SearchUser from './SearchUser';
import { FaImage } from "react-icons/fa6";
import { FaVideo } from "react-icons/fa6";
import { logout } from '../redux/userSlice';
import EditOrderDetails from './OrderPage'
import { twi, twj } from "tw-to-css";

const Sidebar = () => {
    const user = useSelector(state => state?.user)
    const [order, setOrder] = useState([])
    const [editUserOpen, setEditUserOpen] = useState(false)
    const [editOrderOpen, setEditOrderOpen] = useState(false)
    const [allUser, setAllUser] = useState([])
    const [openSearchUser, setOpenSearchUser] = useState(false)
    const socketConnection = useSelector(state => state?.user?.socketConnection)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [disabled, setDisabled] = useState([])
    useEffect(() => {
      //  setDisabled(true)
        if ((socketConnection) && (user.id !== '')){
            console.log('sidebar user', user);
            socketConnection.emit('sidebar', user.id)
            socketConnection.on('conversation', (data) => {
                console.log('GOT IT sidebar conversation', data)
                //  setDisabled(false)
                const conversationUserData = data.map((conversationUser, index) => {


                    if (conversationUser?.sender[0]?.id === conversationUser?.receiver[0]?.id) {
                        return {
                            ...conversationUser,
                            userDetails: conversationUser?.sender[0]
                        }
                    }
                    else if (conversationUser?.receiver[0]?.id !== user?.id) {
                        return {
                            ...conversationUser,
                            userDetails: conversationUser.receiver[0]
                        }
                    } else {
                        return {
                            ...conversationUser,
                            userDetails: conversationUser.sender[0]
                        }
                    }
                })
                console.log('sidebar conversationUserData', conversationUserData);
                let sorted = conversationUserData.sort((a, b) => b.date.localeCompare(a.date));
                console.log('sidebar conversationUserData sorted', sorted);  //Message 
                setAllUser(conversationUserData)
            })
        }
    }, [socketConnection, user])

    const handleOnClick = (index) => {
   
  //     console.log('hi');

    }

    const handleLogout = () => {
        dispatch(logout())
        navigate("/email")
        localStorage.clear()
    }
    //////////////////////////////////

                            
    const styleInline = twi(`"inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out" `);
   // console.log(styleInline);

    /////////////////////////////////
    return (
        <div className='w-full h-full grid grid-cols-[48px,1fr] bg-white'>
            {/*      <div className='sidebar-body'>   */}

            <div className='bg-slate-100 w-12 h-full rounded-tr-lg rounded-br-lg py-5 text-slate-600 flex flex-col justify-between'>
                {/*  <div className='sidebar-header'> */}
                <div>
                    <NavLink className={({ isActive }) => `w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded ${isActive && "bg-slate-200"}`} title='chat'>
                        {/* <NavLink className={({ isActive }) =>`sidebar-chat`} title='chat'>  */}

                        <IoChatbubbleEllipses
                            size={20}
                        />
                    </NavLink>

                    <div title='add friend' onClick={() => setOpenSearchUser(true)} className='w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded' >
                        {/*  <div title='add friend' onClick={() => setOpenSearchUser(true)} className='sidebar-addfriend' > */}
                        <FaUserPlus size={20} />
                    </div>
                </div>

                <div className='flex flex-col items-center'>
                    <button className='mx-auto' title={user?.name} onClick={() => setEditUserOpen(true)}>
                        <Avatar
                            width={40}
                            height={40}
                            name={user?.name}
                            imageUrl={user?.profile_pic}
                            userId={user?.id}
                        />
                    </button>
                    <button title='logout' className='w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded' onClick={handleLogout}>
                        <span className='-ml-2'>
                            <BiLogOut size={20} />
                        </span>
                    </button>
                </div>
            </div>

            <div className='w-full'>
                <div className='h-16 flex items-center'>
                    <h2 className='text-xl font-bold p-4 text-slate-800'>ИНТЕРКОМ</h2>
                </div>
                <div className='bg-slate-200 p-[0.5px]'></div>

                <div className=' h-[calc(100vh-65px)] overflow-x-hidden overflow-y-auto scrollbar'>
                    {
                        allUser.length === 0 && (
                            <div className='mt-12'>
                                <div className='flex justify-center items-center my-4 text-slate-500'>
                                    <FiArrowUpLeft
                                        size={50}
                                    />
                                </div>
                                <p className='text-lg text-center text-slate-400'>Добавьте пользователя в диалог</p>
                            </div>
                        )
                    }

                    {

                        allUser.map((conv, index) => {
                            const allUserOrders = conv.orders

                            return (
                                <>


                                    <NavLink to={"/" + conv?.userDetails.id} key={conv?.id} className='flex items-center gap-2 py-3 px-2 border border-transparent hover:border-primary rounded hover:bg-slate-100 cursor-pointer'>
                                        <div>
                                            <Avatar
                                                imageUrl={conv?.userDetails?.profile_pic}
                                                name={conv?.userDetails.name}
                                                width={40}
                                                height={40}
                                                userId={conv?.userDetails.id}
                                                context={conv?.context}
                                            />
                                        </div>
                                        <div>
                                            <h3 className='text-ellipsis line-clamp-1 font-semibold text-base'>{ !conv?.userDetails.phone && `${conv?.userDetails.name}`}{ conv?.userDetails.phone && `${conv?.userDetails.name} : ${conv?.userDetails.phone}`}</h3>
                                            <div className='text-slate-500 text-xs flex items-center gap-1'>
                                                <div className='flex items-center gap-1'>
                                                    {
                                                        conv?.lastMsg?.imageUrl && (
                                                            <div className='flex items-center gap-1'>
                                                                <span><FaImage /></span>
                                                                {!conv?.lastMsg?.text && <span>Image</span>}
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        conv?.lastMsg?.videoUrl && (
                                                            <div className='flex items-center gap-1'>
                                                                <span><FaVideo /></span>
                                                                {!conv?.lastMsg?.text && <span>Video</span>}
                                                            </div>
                                                        )
                                                    }
                                                </div><p className='text-ellipsis text-blue font-bold'>{conv?.outside}</p>
                                                <p className='text-ellipsis line-clamp-1'>{(conv?.lastMsg?.text).substring(0,25)}</p>
                                            </div>
                                        </div>

                                        {
                                            Boolean(conv?.unseenMsg) && (
                                                <p className='text-xs w-6 h-6 flex justify-center items-center ml-auto p-1 bg-primary text-white font-semibold rounded-full'>{conv?.unseenMsg}</p>
                                            )
                                        }

                                    </NavLink>
                                    {
                                        allUserOrders.length !== 0 && (
                                            allUserOrders.map((ord, index) => {
                                                return (
                                                    <button className='flex items-center gap-2 py-3 px-2 border border-transparent hover:border-primary rounded hover:bg-slate-100 cursor-pointer'
                                                      /*   disabled={disabled} */
                                                        title={ord?.id}
                                                        name={index}
                                                        id={index}
                                                        onClick={() => { handleOnClick(index); setEditOrderOpen(true); setOrder(ord) }}>


                                                        {/*  <NavLink to={"/order?orderId=" + ord?.id} key={ord?.id} className='flex items-center gap-2 py-3 px-2 border border-transparent hover:border-primary rounded hover:bg-slate-100 cursor-pointer'> */}
                                                        <div className='text-slate-500 text-xs flex items-center gap-1' >
                                                            <p className='text-ellipsis line-clamp-1'>Заказ № {ord?.id} статус: {ord?.status}</p></div>
                                                        {/*       </NavLink> */}
                                                    </button>
                                                )
                                            })

                                        )
                                    }

                                </>

                            )
                        })

                    }

                </div>

            </div>

            {/**edit user details*/}
            {
                editUserOpen && (
                    <EditUserDetails onClose={() => setEditUserOpen(false)} user={user} />
                )

            }

            {/**search user */}
            {
                openSearchUser && (
                    <SearchUser onClose={() => setOpenSearchUser(false)} />
                )
            }

            {
                editOrderOpen && (
                    <EditOrderDetails onClose={() => setEditOrderOpen(false)} user={user} order={order} />
                )
            }

        </div>
    )
}

export default Sidebar

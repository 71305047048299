import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { logout, setOnlineUser, setSocketConnection, setUser } from '../redux/userSlice'
import Sidebar from '../components/Sidebar'
import logo from '../assets/logo.png'
import io from 'socket.io-client'
//require('dotenv').config()

const Home = () => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  /*   const socket = io.connect("http://localhost:8001")
  
    console.log('socket   con',socket) */
  let URL = `${process.env.REACT_APP_BACKEND_URL}`;
  let SOCKET_URL = URL;

  if (window.location.href.includes('localhost')) {
    URL = `${process.env.REACT_APP_BACKEND_LOCAL_URL}${process.env.REACT_APP_PORT_API_LOCAL}`;
    SOCKET_URL = `${process.env.REACT_APP_BACKEND_LOCAL_URL}${process.env.REACT_APP_PORT_SOCKET_LOCAL}`
  }
  console.log('user', user)


  const fetchUserDetails = async () => {
    try {

      console.log(URL);

      const response = await axios({
        url: `${URL}/intercom/user-details`,
        withCredentials: true
      })
      console.log('before setuser--->', response);
      dispatch(setUser(response.data.data))

      if (response.data.data.logout) {

        dispatch(logout())
        navigate("/email")
      }
      console.log("current user Details->", response)
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    fetchUserDetails()
  }, [])

  /***socket connection */
  useEffect(() => {
    const socketConnection = io.connect(`${SOCKET_URL}`, { auth: { token: localStorage.getItem('token') }, })
    console.log('socketcon', socketConnection)
    socketConnection.on('onlineUser', (data) => {
      console.log('data', data)
      dispatch(setOnlineUser(data))
    })
    dispatch(setSocketConnection(socketConnection))
    return () => {
      socketConnection.disconnect()
    }
  }, [])


  const basePath = location.pathname === '/'
  return (
    <div className='grid lg:grid-cols-[300px,1fr] h-screen max-h-screen'>
      <section className={`bg-white ${!basePath && "hidden"} lg:block`}>
        <Sidebar />
      </section>

      {/**message component**/}

      {console.log('location.pathname', location.pathname)}

      <section className={`${basePath && "hidden"}`} >
        {console.log('BP -------------->', basePath && "hidden")}
        <Outlet />
      </section>


      <div className={`justify-center items-center flex-col gap-2 hidden ${!basePath ? "hidden" : "lg:flex"}`}>
        <div>
          {/* <img
                src={logo}
                width={250}
                alt='logo'
              /> */}
        </div>
        <p className='text-lg mt-2 text-slate-500'>Выберите пользователя для отправки сообщения</p>
      </div>
    </div>
  )
}

export default Home
